import Photo from './photo3.webp';
import {useState, useEffect, useRef, forwardRef, useImperativeHandle} from 'react';

const play = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 798 886"><path d="M80 .59c38.31.31 73.87 25.59 106 43.98l41 23.02 66 37.92 75 43.31 132 75.75 198 113.48 54 31.4c24.87 17.24 46.36 43.99 46 75.55-.28 23.78-17.13 48.74-35 63.2-15.89 12.85-33.34 22.12-51 32.23l-65 37.18-240 137.8-40 22.46-27 15.96-23 12.63-90 52.08-25 13.92-48 27.44c-9.95 5.48-19.38 11.54-30 15.79-12.8 5.13-30.18 9.97-44 9.97-5.11 0-15.01-1.45-20-2.82-32.5-8.9-48.28-33.76-55.87-64.84C1.96 809.13.01 796.08 0 787V99c.04-28.64 10.74-64.57 34-82.65 7.24-5.63 9.54-6.84 18-10.34C63.99 1.04 67.8 1.58 80 .59Z"/></svg>;
const pause = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 384"><path d="M9 .57 35 0h45c12.78.06 15.98 4.81 16 17v350c-.02 13.19-3.81 16.98-17 17H15c-11.39-.14-14.95-5.11-15-16V15C.09 7.52 1.63 3.47 9 .57Zm160 0L195 0h45c12.78.06 15.98 4.81 16 17v350c-.02 13.19-3.81 16.98-17 17h-64c-11.39-.14-14.95-5.11-15-16V15c.09-7.48 1.63-11.53 9-14.43Z"/></svg>;

const Player = forwardRef((props, ref) => {

    const [playing, setPlaying] = useState(false);
    const fileRef = useRef();
    const timeline = useRef();
    const currentTime = useRef();
    const duration = useRef();
    const timelineHolder = useRef();

    const toggle = () => {
        if (!playing) fileRef.current.play();
        else fileRef.current.pause();
        setPlaying(!playing);
    }

    const handleTime = (time, current) => {
        var seconds = ("0" + Math.floor(current % 60)).slice(-2);
        var newTime = `${Math.floor(current / 60)}:${seconds}`;
        timeline.current.style.width = `${time}%`;
        currentTime.current.innerText = `${newTime}`;
    }

    const handleClick = (e) => {
        console.log((e.clientX - timelineHolder.current.getBoundingClientRect().x) / timelineHolder.current.getBoundingClientRect().width * fileRef.current.duration);
        fileRef.current.currentTime = (e.clientX - timelineHolder.current.getBoundingClientRect().x) / timelineHolder.current.getBoundingClientRect().width * fileRef.current.duration;
    }

    useImperativeHandle(ref, () => ({
        
        pauseAudio(){
            setPlaying(false);
            fileRef.current.pause();
        }

    }), []);

    return (

        <div className="player">   

            <audio ref={fileRef} onLoadedMetadata={() => {duration.current.innerText = `${Math.floor(fileRef.current.duration / 60)}:${("0" + Math.floor(fileRef.current.duration % 60)).slice(-2)}`;}} onTimeUpdate={() => {handleTime(fileRef.current.currentTime / fileRef.current.duration * 100, fileRef.current.currentTime);}} onEnded={() => {setPlaying(false);}}>
                <source src={props.file}></source>
            </audio>

            <div className="player-top">
                <div className="play-button" onClick={() => {toggle(); props.handleClick();}}>{playing ? pause : play}</div>
                <img alt="Marla Patterson Headshot" draggable="false" src={Photo}></img>
            </div>
            <div className="player-bot">
                <div className="player-title">{props.title}</div>
                <div className="player-details">
                    <div ref={currentTime} className="current-time no-grow-shrink">0:00</div>
                    <div ref={timelineHolder} className='timeline' onClick={handleClick}><div ref={timeline} className="current-timeline"></div></div>
                    <div ref={duration} className="full-time no-grow-shrink">0:00</div>
                </div>
            </div>
        </div>

    );

});

export default Player;