import Headshot from './headshot.webp';
import poseImage from './pose.webp';
import rowImage from './row.webp';
import Audiobook from './Marla-Audiobook.wav';
import Commercial from './Marla-Commercial.wav';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import {useRef, useLayoutEffect} from 'react';

import Player from './Player.js';
import emailjs from 'emailjs-com';

function App() {

  const instagram = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M131 .11h197c22.71-.11 46.63-1.46 69 2.32 34.61 5.86 66.26 26.71 87.13 54.57 8.45 11.29 13.67 20.8 18.55 34 5.58 15.09 9.29 31.85 9.32 48v234c-.12 74.8-64.2 138.88-139 139H196c-24.58 0-57.53 1.54-81-2.43-31.43-5.33-61.16-23.48-81.7-47.57-15.18-17.81-25.9-40.04-30.49-63C-.49 382.52 0 367.6 0 351V192c0-23.49-1.51-55.65 2.61-78C10.51 71.29 40.06 33.24 79 14.26 98.12 4.94 110.66 3.33 131 .11Zm256 86.21c-6.61 1.49-12.04 3.9-16.91 8.77-20.19 20.2-7.25 56.19 21.91 56.9 9.94.24 19.87-3.47 26.53-11.08 13.85-15.81 9.49-41.94-9.53-51.53-7.21-3.65-14.12-3.92-22-3.06Zm-138 37.96c-16.48 2.37-23.55 2.44-40 8.41-29.78 10.82-54.73 33.54-69.69 61.31-27.05 50.22-16.97 114.48 22.73 154.99C185.67 373.1 220.26 387.6 254 388c19.23.22 38.37-4.04 56-11.58 13.55-5.79 23.91-13.27 35-22.84 44.39-38.35 55.21-108.48 27.69-159.58-16.66-30.94-43.29-52.99-76.69-63.66-13.13-4.19-33.31-7.33-47-6.06Zm-6 46.16c26.9-3.45 54.3 4.7 73.91 23.73 31.57 30.61 33.74 83.15 6.01 116.83-7.15 8.68-15.02 15.32-24.92 20.69-17.98 9.75-29.2 10.54-49 10.31-5.74-.07-10.48-1.21-16-2.63-27.7-7.13-49.7-28.47-58.92-55.37-4.45-12.97-4.1-19.8-4.08-33 .01-9.59 2.35-19.16 6.01-28 12.26-29.57 36-46.84 66.99-52.56Z"/></svg>;
  const tiktok = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 651 750"><path d="M344 0h129v14c.02 15.65 5.67 37.43 11.45 52 19.37 48.82 61.81 88.4 111.55 105 12.95 4.32 29.33 8.98 43 9h12v128l-24-1.83c-46.56-4.66-83.49-13.97-125-36.48L473 251v268c-.04 24.41-8.32 60.3-17.4 83-9.35 23.39-25.92 50.35-42.77 69-30.68 33.97-72.5 60.17-116.83 71.85-14.38 3.78-38.19 7.13-53 7.15h-15c-33.64-.05-76.57-13.35-106-29.31-17.86-9.68-37.64-25.37-52-39.69-19.68-19.62-35.89-42.68-47.3-68l-8.01-19C8.55 576.39 1.22 542.48 1 524c-.55-47.66 8.22-90.78 33-132 22.14-36.83 50.45-64.91 88-85.86 27.63-15.42 57.67-24.56 89-28.42l18-1.72h18l22 2v131c-10.96-1.69-15.89-4.94-29-5h-8c-38.61.06-75.33 25.48-93.11 59-31.98 60.29 1.76 136.7 67.11 155.42 6.55 1.88 23.52 4.86 30 4.4 16.29-1.16 31.15-3.84 46-11.08 36.12-17.61 61.94-56.21 62-96.74V0Z"/></svg>;
  const facebook = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240"><path d="M128 240H42c-25.26-.04-41.96-16.74-42-42V42C.04 16.74 16.74.04 42 0h156c25.26.04 41.96 16.74 42 42v132c0 19.65 3.27 41.18-13.01 55.82C214.85 240.72 203.03 240 188 240h-23v-93h32l4-36h-36c0-9.27-2.03-31.37 5.21-37.49C176.2 68.45 193.99 70 202 70V38c-8.97-1.18-11.23-.94-20-.77-5.13-.27-13.1-.65-18 0-15.71 3.07-28.53 14.53-33.23 29.77-3.47 11.26-2.77 31.79-2.77 44H97v36h31v93Z"/></svg>;


  const chat = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M401.808 32.656H110.192C49.44 32.656 0 83.184 0 145.248v111.536c0 62.096 49.44 112.608 110.192 112.608h184.32l134.016 109.952v-113.36C476.992 353.664 512 309.04 512 256.784V145.248c0-62.08-49.44-112.592-110.192-112.592zM480 256.784c0 40.992-29.808 75.392-69.344 80.032l-14.128 1.664v73.216l-90.576-74.304h-195.76c-43.104 0-78.192-36.16-78.192-80.608V145.248c0-44.432 35.072-80.592 78.192-80.592h291.616c43.104 0 78.192 36.16 78.192 80.592v111.536z"/></svg>;
  const file = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 359"><path d="M273 .53 317 0h85c26.9.04 45.96 19.09 46 46v266c-.04 25.74-17.61 45.96-44 46H44c-18.89-.03-35.72-11.85-41.64-30C.57 322.51.01 316.75 0 311V92c.04-26.73 17.61-46.96 45-47h170c11.22-.02 13.28-5.28 21-13 9.19-9.19 25.09-27.91 37-31.47Zm10 32.07c-5.12 2.68-8.94 7.34-13 11.4l-20 20c-8.38 8.38-9.86 12.98-22 13H48c-4.6.02-8.16.07-11.79 3.39-4.32 3.95-4.2 8.26-4.21 13.61v209c0 4.62-.59 11.97 1.45 16 3.42 6.73 7.88 6.99 14.55 7h352c12.82-.02 15.98-4.67 16-17V57c0-4.85.67-13.87-1.45-17.98-3.45-6.7-8.96-7.01-15.55-7.02h-77l-39 .6ZM92 262.53l30-.53h226c5.29.01 9.56-.1 13.96 3.42 8.37 6.69 7.9 19.68-1 25.7-4.32 2.93-8.99 2.87-13.96 2.88H101c-5.78-.01-11.23-.22-15.79-4.39-9.2-8.42-5.87-23.02 6.79-27.08Z"/></svg>;

  gsap.registerPlugin(ScrollTrigger);

  const AudiobookAudio = useRef(null);
  const CommercialAudio = useRef(null);
  const contactForm = useRef(null);
  const mastHead = useRef(null);
  const footer = useRef(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_9grd93e', 'template_el1s42k', contactForm.current, '-SWvGa0zQx74M7vBb')
      .then((result) => {
          contactForm.current.classList.add("message");
      }, (error) => {
        contactForm.current.classList.add("message");
      });
  }

  useLayoutEffect(() => {
  
    window.onscroll = () => {
      mastHead.current.classList = `masthead ${window.pageYOffset >= mastHead.current.offsetHeight ? "small" : ""}`;
    }

    let ctx = gsap.context(() => {

      gsap.to(".footer", {
        scrollTrigger: {
          trigger: "#work",
          start: "top 95%",
          end: "100% 90%",
          toggleActions:"restart play reverse reverse",
          ease: "back",
          onEnter() {
            footer.current.classList.add('dark');
          },
          onEnterBack(){
            footer.current.classList.add('dark');
          },
          onLeave() {
            footer.current.classList.remove('dark');
          },
          onLeaveBack() {
            footer.current.classList.remove('dark');
          },
        }
      });

    }); 
    
    return () => ctx.revert();
    
  }, []);

  return (
    <>
      <div ref={mastHead} className="masthead">
        <a href="#" className="logo">MP</a>
        <div className='masthead-nav'>
          <a href="#about" className="nav-url">About</a>
          <a href="#work" className="nav-url">Work</a>
          <a href="#contact" className="nav-url">Contact</a>
        </div>
      </div>

      <div ref={footer} className="footer">
        <a href="https://www.instagram.com/mdpatt50/" target="_blank" rel="noreferrer" className="social-url">{instagram}</a>
        <a href="https://www.tiktok.com/@marlapatterson460" target="_blank" rel="noreferrer" className="social-url">{tiktok}</a>
      </div>

      <section className="main">
        <div className="first-half">
          <img alt="Marla Patterson Headshot" className="main-img" src={Headshot}></img>
        </div>
        <div className="second-half">
          <div className="main-description">VOICE ACTOR</div>
          <div className="main-title">
            Marla
            <div>Patterson</div>
          </div>
          <a className="button" href="#work">Listen In</a>
        </div>
      </section>

      <section id="about">
        <div className='content flex'>
          <div className="split-container first">
            <div className="section-desc">Get to know,</div>
            <div className="section-title">Marla</div>
            <div className="section-content">

              Hello, my name is Marla Patterson, as of now I’m employed in Corporate America for 30+ years and I’m excited about my transition as a newcomer to Voiceover acting. 
              <br></br>
              <br></br>
              My niche is audio books, my favorite audio books are mystery and suspense, documentaries, eLearning for academic listeners and users of online study or with reading software for students with reading level challenges.
              <br></br>
              <br></br>
              To accomplish this, I’ve been introduced to different genres, narrations and styles of voiceover that I had never imagined existed. I am setting up a home studio, training for the vocal acting industry to successfully narrate voice expressions that entertain and captivate.
              <br></br>
              <br></br>
              I find voiceover thrilling and hard work worth studying and I love building up products and stories writers have crafted for the audiences to enjoy.  My second favorite is instructional comprehension reading that will engage and teach to inspire servicing for students.
              <br></br>
              <br></br>
              Ultimately, I’m seeking Voiceover opportunities for profit and non-profit organizations, promos, documentaries that will utilize and thrive my voice over talent.
            </div>

          </div>

            <div className="split-container second">
              <img alt="Marla Patterson" className='headshot' src={poseImage}></img>
            </div>

            <img alt="Marla Patterson Comp Card" className="image-row" src={rowImage}></img>

        </div>
      </section>

      <section id="work">
        <div className='content'>

          <div className="flex-row work-row">
            <div className="title">Portfolio</div>

            <div className="flex-row">
              <div className="portfolio-cat">
                <div className="portfolio-row">
                  <div className="icon">{chat}</div>
                  <div className="alt-text">Languages</div>
                </div>
                <div className="flex-holder">
                  <div className="element">English</div>
                </div>
              </div>

              <div className="portfolio-cat">
                <div className="portfolio-row">
                  <div className="icon">{file}</div>
                  <div className="alt-text">Categories</div>
                </div>
                <div className="flex-holder">
                  <div className="element">Commercials</div>
                  <div className="element">Audiobooks</div>
                  <div className="element">Advertisements</div>
                </div>
              </div>
            </div>
          </div>

          <div className="all-work flex-row">
            <Player ref={AudiobookAudio} file={Audiobook} handleClick={() => {CommercialAudio.current.pauseAudio();}} title={"Audiobook Demo"}></Player>
            <Player ref={CommercialAudio} file={Commercial} handleClick={() => {AudiobookAudio.current.pauseAudio();}} title={"Commercial Demo"}></Player>
          </div>

        </div>
      </section>

      <section id="contact">
        <div className='content flex-col-cen'>
          <div className="title">Let's connect.</div>
          <form ref={contactForm} onSubmit={sendEmail} className='contact-form'>
              <div className="form-row">
                <div className='form-title'>Name</div>
                <input placeholder="Your name" type="text" name="user_name"></input>
              </div>
              <div className="form-row">
              <div className='form-title'>Email</div>
                <input placeholder="Your email adress" type="text" name="user_email"></input>
              </div>
              <div className="form-row">
              <div className='form-title'>Message</div>
                <textarea placeholder="Your message" type="text" name="message"></textarea>
              </div>
              <div className="form-row-button">
                <button className="submit-button">Submit</button>
              </div>
              <div className="flag">Thanks, your message was sent!</div>
          </form>
        </div>
      </section>

    </>
  );
}

export default App;
